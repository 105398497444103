@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Gilroy';
    font-weight: 300;
    src: url('./assets/fonts/Gilroy-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: 800;
    src: url('./assets/fonts/Gilroy-ExtraBold.otf') format('opentype');
  }
}
